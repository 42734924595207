@import '@crystal-eyes/constants.scss';

.avatarContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avatar {
  border-radius: 50%;
  object-fit: cover;
  color: white;
  text-align: center;
}

.badgeContainer {
  position: absolute;
  top: -1px;
  right: -1px;
}

.avatarSVG {
  image {
    clip-path: inset(0 0 0 0 round 50%);
  }
}

.textPill {
  width: fit-content;
  text-align: center;
  background: $blue;
  padding: 6px 12px;
  border-radius: 100px;
  pointer-events: none;
  margin-top: -12px;
  z-index: 1;
  white-space: nowrap;
  font-size: 0.875rem;
}

.discAvatar {
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  align-items: center;

  img {
    object-fit: cover;
  }

  .discPill {
    width: fit-content;
    color: white;
    font-weight: 400;
    font-size: 0.93rem;
    text-align: center;

    padding: 6px 12px;
    letter-spacing: 1px;
    border-radius: 100px;
    pointer-events: none;
    margin-top: -22px;
    z-index: 500;
  }

  .editMessage {
    display: flex;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all 0.3s;

    pointer-events: none;
    background: rgba(0, 0, 0, 0.4);
    color: white;
    border-radius: 50%;
    line-height: 1.5em;

    position: absolute;

    z-index: 100;
  }

  &.editable {
    &:hover {
      cursor: pointer;

      .editMessage {
        opacity: 1;
      }
    }
  }

  @media print {
    print-color-adjust: exact !important;
  }
}

.cropped {
  img {
    border-radius: 50%;
  }
}

.emptyAvatar {
  z-index: 1;
  position: absolute;
  left: 0px;
}
