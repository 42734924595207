@import '@crystal-eyes/constants.scss';
@import '@crystal-eyes/helpers.scss';

.sectionCard {
  border-radius: 4px;
  border: 1px solid $border-gray;
  max-width: 60rem;
  background-color: white;
  break-inside: avoid;

  .icon {
    @include svg-bold();

    &.myPersonality {
      stroke-width: 1;
    }
  }

  &:global(.no-print) {
    @media print {
      display: none;
      height: 0;
      visibility: hidden;
    }
  }

  &:global(.yes-print) {
    @media print {
      print-color-adjust: exact !important;
    }
  }

  @media (max-width: $main-content-width) {
    max-width: 100%;
    width: calc(100vw - 40px);
    box-sizing: border-box;
    margin: 0 20px;
    overflow-x: hidden;
  }

  :global .section-card-heading {
    border-bottom: 1px solid $border-gray;
    padding: 14px 24px;
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr auto;
    font-size: 1.2rem;

    :global(.title-subtitle) {
      margin: 0 12px;

      h2 {
        margin: 0 0;
        flex-grow: 1;
      }

      :global(.subtitle) {
        font-size: 0.8rem;
      }
    }

    :global(.print-link) {
      text-decoration: none;
      color: #aeaeae;
      display: flex;
      justify-self: center;
      font-size: 0.7rem;
      align-self: center;
      transition: all 0.2s;

      @media print {
        display: none;
      }

      & svg {
        margin-right: 0.25rem;
      }

      &:hover {
        cursor: pointer;
        text-decoration: underline;
        transform: scale(1.2);
        color: #7e7e7e;
        text-decoration: none;
      }
    }
  }

  :global .card-content {
    :global .trait:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
  }

  :global(.runtime-error-message) {
    color: red;
    text-align: center;
  }
}
