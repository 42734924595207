@import '@crystal-eyes/constants';@import '@crystal-eyes/helpers.scss';
@import '@crystal-eyes/constants.scss';

.headerOuterContainer {
  margin-top: 2rem;
  width: 100%;
  max-width: $profile-table-max-width;
  border-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  h1 {
    color: black;
    font-size: 1.125rem;
    margin: 0;
  }

  p {
    color: #7e7e7e;
    font-size: 1.2em;
    margin: 0.25rem;
  }
}

.headerContainer {
  padding: 20px 24px 10px;
  border: 1px solid $crystal-line-light-gray;
  border-bottom: none;
  border-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $mobile-width) {
    margin: 0 20px;
  }
}

.buttonContainer {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-right: 16px;
}

.meetingDrawer {
  width: calc(100% - 378px);
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
  right: 0;
  margin: 15px 15px 0;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  font-family: var(--font-lato), Lato;
}

.topBar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .toggleBar {
    width: 211px;
    height: 8px;
    background: $bg-gray-3;
    border-radius: 10px;
  }
}

.iconWrapper {
  cursor: pointer;
  &:hover {
    opacity: 0.87;
  }
}

.icon {
  @include svg-bold();
  color: $action-blue;
  margin-left: 4px;
  height: 20px;
}
