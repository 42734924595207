.loading-container {
  padding-top: 20px;
}

.shimmer-title {
  margin-bottom: 20px;

  &-line {
    width: 100%;
    height: 24px;
    border-radius: 20px;

    &:first-child {
      width: 100% !important;
    }

    &:last-child {
      width: 40%;
    }
  }
}

.grid {
  display: grid;
}

.grid-gap-10 {
  gap: 10px;
}

.grid-gap-15 {
  gap: 15px;
}

.grid-gap-20 {
  gap: 20px;
}

.grid-gap-30 {
  gap: 30px;
}

.shimmer {
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 1000px 100%;
  animation: shimmer 2.2s linear infinite forwards;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
