@import '@crystal-eyes/constants.scss';

.quickMeetingTips {
  :global(.section-card-content) {
    padding: 20px 20px;

    @media (max-width: $main-content-width) {
      padding: 0 20px;
    }
  }

  :global(.options) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  :global(.suggestions) {
    display: grid;
    width: 100%;
    max-width: 720px;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    margin: 0 auto;
    border-top: 1px solid #cecece;
    border-bottom: 1px solid #cecece;

    @media (max-width: $main-content-width) {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
    }

    :global(.suggestions-section) {
      h4 {
        font-weight: bold;
        margin-bottom: 20px;
        font-size: 0.875rem;
      }

      :global(.suggestion) {
        border-left-width: 2px;
        border-left-style: solid;
        padding: 2px 0.5em;
        margin: 0.5em 0;
        font-size: 0.875rem;
      }
    }

    :global(.dos) {
      h4 {
        color: #11b21b;
      }
      :global(.suggestion) {
        border-color: rgb(183, 232, 186);
        &:hover {
          border-color: #11b21b;
        }
      }
    }

    :global(.donts) {
      h4 {
        color: #db3c48;
      }
      :global(.suggestion) {
        border-color: rgb(244, 196, 200);
        &:hover {
          border-color: #db3c48;
        }
      }
    }
  }

  :global(.cta) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;

    a {
      text-transform: uppercase;
    }

    padding-bottom: 20px;
  }

  :global(.suggestions-section.paywalled) {
    filter: blur(8px);
    pointer-events: none;
  }
}
