@import '@crystal-eyes/constants.scss';
@import '@crystal-eyes/helpers.scss';

.discColor {
  @extend .disc-color;
}

.assessmentTableWrapper {
  border-radius: 4px;
  border: 1px solid $crystal-line-light-gray;
  background: $white;

  .tableHeaderWrapper {
    padding: 18px 24px;
    background: rgba(44, 167, 228, 0.05);
    border-bottom: 1px solid $gray-background;
    border-radius: 3px 3px 0 0;
    color: $blue;
    font-size: 1.125em;
    line-height: 1.4em;
    font-weight: 600;
    display: flex;
    gap: 14px;

    svg {
      @include svg-bold();
    }
  }

  .tableContainer {
    width: 100%;
    max-width: 1040px;
    background-color: $white;
    padding: 0 0 16px;
    animation: fade-in 0.7s;
    border-spacing: 0px;
    border-radius: 0 0 4px 4px;

    thead {
      tr {
        display: none;

        @media (min-width: 768px) {
          flex-direction: row;
          display: flex;
          padding: 8px 24px;
        }
      }
    }

    th {
      display: flex;
      @media (min-width: 768px) {
        flex-grow: 1;
        margin-right: 12px;
      }
    }

    tbody {
      tr {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid $gray-background;
        padding: 8px 24px 0;
        background: $white;
        animation: drop-in 1s ease;

        @media (min-width: 768px) {
          flex-direction: row;
          border: none;
          padding: 4px 24px;
        }
      }

      td {
        flex-basis: 100%;
        text-align: left;
        overflow: hidden;
        align-items: center;
        display: flex;
        @media (min-width: 768px) {
          flex-basis: 0;
          flex-grow: 1;
          margin-right: 12px;
        }
      }
    }
  }

  .tableHead {
    flex-basis: 100%;

    @media (min-width: 768px) {
      flex-basis: auto;
    }
  }
  .table-header-cell:last-child,
  .table-cell:last-child {
    margin-right: 0;
  }

  .thinSection {
    flex-grow: 0.5;
  }

  .averageSection {
    flex-grow: 0.8;
  }

  .loadingContainer {
    padding: 24px;
    background: $white;
    min-height: 300px;
  }

  .refreshIcon {
    height: 1em;
    width: 1em;
    margin-right: 8px;
  }

  .paginationWrapper {
    padding-bottom: 16px;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes drop-in {
    0% {
      opacity: 0;
      transform: scale(0.9);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
}
