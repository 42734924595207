@import '@crystal-eyes/constants.scss';

.teamRoles {
  :global(.section-card-content) {
    padding: 20px 20px;

    select {
      margin-left: 0.5em;
    }

    @media (max-width: $main-content-width) {
      select {
        margin-left: auto;
      }
    }
  }

  :global(.role-selection) {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: $main-content-width) {
      flex-direction: column;
    }
  }

  :global(.situations) {
    display: grid;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
    width: 100%;

    border-top: 1px solid #cecece;
    margin-top: 40px;

    @media (max-width: $main-content-width) {
      margin-top: 20px;
    }
  }

  :global(.situation) {
    h4 {
      font-size: 1.125rem;
    }

    li {
      line-height: 1.8rem;
      font-size: 0.875rem;
    }

    @media (max-width: $main-content-width) {
      ul {
        padding-inline-start: 10px;
      }
    }
  }

  :global(.datums.paywalled) {
    filter: blur(8px);
    pointer-events: none;
  }
}

@media (max-width: $main-content-width) {
  .label {
    margin-bottom: 1rem;
  }
}
