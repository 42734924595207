@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@include keyframes(placeholder-shimmer) {
  from {
    background-position: -468px 0;
  }

  to {
    background-position: 468px 0;
  }
}

.loadingBar {
  height: 1.5rem;
  border-radius: 0.75rem;

  @include animation('placeholder-shimmer 1.8s linear infinite');
  animation-fill-mode: forwards;
  background: #f6f7f8;
  background: linear-gradient(to right, #f8f8f8 8%, #dfdfdf 38%, #f8f8f8 54%);
  background-size: 1000px 640px;

  position: relative;
}

.loadingCircle {
  height: 200px;
  border-radius: 50%;
  @include animation('placeholder-shimmer 1.8s linear infinite');
  animation-fill-mode: forwards;
  background: #f6f7f8;
  background: linear-gradient(to right, #f8f8f8 8%, #dfdfdf 38%, #f8f8f8 54%);
  background-size: 1000px 640px;
  position: relative;
}
