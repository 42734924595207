.pill {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border-radius: 30px;
  line-height: 1em;

  padding: 0.5em 1em;
  border-width: 1px;
  border-style: solid;
  pointer-events: none;
  text-transform: uppercase;
  font-size: 0.85em;
  letter-spacing: 0.2em;
}