.whatIsCrystal {
  :global(.section-card-content) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 10px;

    padding: 40px 40px;

    p {
      font-size: 1.4rem;
    }
  }
}
