@import '@crystal-eyes/constants.scss';

$sidebar-width: 190px;
$content-row-gap: 16px;
$sidebar-content-gap: 30px;

.profilePage {
  display: grid;
  grid-template-rows: auto 1fr;
  width: 100%;
  min-height: 100vh;

  h1,
  h2 {
    scroll-margin-top: 50px;
  }

  :global(.print-page-break) {
    @media print {
      page-break-after: always;
    }
  }

  & > .profileMain {
    display: grid;
    grid-template-columns: $sidebar-width calc(
        1040px - $sidebar-width - $sidebar-content-gap
      );
    column-gap: $sidebar-content-gap;
    justify-content: center;
    width: 100%;

    background-color: rgb(245, 245, 245);
    padding: 40px 0;

    @media (max-width: 1040px) {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;

      article {
        width: 100vw;
        box-sizing: border-box;
      }
    }
  }

  & > .profileMain.profileCompletenessActive {
    @media (min-width: calc($min-new-profile-page-width)) {
      grid-template-columns: $sidebar-width calc(
          $min-new-profile-page-width - $sidebar-width - $sidebar-content-gap
        );
    }
    @media (min-width: calc($new-profile-page-width + 32px)) {
      grid-template-columns: $sidebar-width calc(
          $new-profile-page-width - $sidebar-width - $sidebar-content-gap
        );
    }
  }

  .withSideNav {
    grid-template-columns: 1040px;
  }

  :global(#content) {
    display: grid;
    row-gap: $content-row-gap;

    section {
      display: grid;

      & > div {
        margin-bottom: $content-row-gap;
      }

      & > div:last-child {
        margin-bottom: 0;
      }
    }

    section > h1 {
      visibility: hidden;
      height: 0;
      margin: 0 0;
    }

    section h2 {
      font-size: 1.125rem;
      font-weight: bold;
    }
  }
}

.personalitySection {
  column-gap: $content-row-gap;
  .personalityLeftContainer {
    display: grid;
    row-gap: $content-row-gap;
    h1 {
      visibility: hidden;
      height: 0;
      margin: 0 0;
    }

    h2 {
      font-size: 1.3rem;
      font-weight: bold;
    }
  }
}
